<template>
  <v-container fluid class="pa-0 ">
   <banner :title="$t('contact.title')"></banner>
    <v-container fluid class="py-10">
    <v-row class="pa-lg-16 pa-10 mx-0 justify-center d-flex">
      <v-col cols="12" lg="4">
        <v-row class="justify-center justify-lg-start">
          <h2 class="h2 mb-2">{{$t('contact.weWantToKnowYou')}}</h2>
        </v-row>
        <v-row class="justify-center justify-lg-start">
          <p class="josefin-light text-center text-lg-left">
            {{$t('contact.knowMore')}}
          </p>
        </v-row>
        <v-row>
          <div class="form_contact_us justify-center">
            <v-form ref="form" class="justify-center">
              <v-text-field
                :label="$t('contact.name')"
                required
                solo
                v-model="emailMessage.name"
                :counter="60"
                :rules="validationRules.nameRules"
              ></v-text-field>
              <v-text-field
                solo
                :label="$t('contact.email')"
                required
                v-model="emailMessage.email"
                :rules="validationRules.emailRules"
              ></v-text-field>
              <v-textarea
                class="text_area"
                solo
                :label="$t('contact.message')"
                required
                v-model="emailMessage.message"
                :rules="validationRules.messageRules"
                :counter="450"
                no-resize
              ></v-textarea>
              <p  class="font-12 text-center text-lg-left" v-html="$t('contact.infoProtected')">
              </p>
              <v-row justify="center">
                <v-btn class="mr-4 mt-2 btn_second" @click="onSubmit" :loading="loading">
                  {{$t('contact.btnSend')}}
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" lg="2" class="d-none d-lg-flex">
        <v-img src="@/assets/Linea.png" contain class="linea mt-n10"></v-img>
      </v-col>
      <v-col cols="12" lg="4" class="mt-16 mt-lg-0">
        <v-row>
          <v-col cols="12" class="justify-center d-flex justify-lg-start">
            <h2 class="h2 text-uppercase">{{$t('contact.phone')}}</h2>
          </v-col>
          <v-col cols="12" class="justify-center d-flex justify-lg-start">
            <a href="tel:8093697327" class="josefin-light p">809.369.7327</a>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="justify-center d-flex justify-lg-start">
            <h2 class="h2 text-uppercase">{{$t('contact.email')}}</h2>
          </v-col>
          <v-col cols="12">
            <v-row class="justify-center d-flex justify-lg-start mx-0">
              <a href="mailto:info@rondonmiguel.com" class="p josefin-light"
                >info@rondonmiguel.com</a
              >
            </v-row>
            <v-row class="justify-center d-flex justify-lg-start mx-0">
              <a href="mailto:ventas@rondonmiguel.com" class="p josefin-light"
                >ventas@rondonmiguel.com</a
              >
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="justify-center d-flex justify-lg-start">
            <h2 class="h2 text-uppercase">{{$t('contact.office')}}</h2>
          </v-col>
          <v-col cols="12" class="justify-center d-flex justify-lg-start">
            <p class="p josefin-light text-center text-lg-left">
              Calle Vista Verde #11, Arroyo Hondo, Distrito Nacional R.D.
            </p>
          </v-col>
        </v-row>
        <br />
      </v-col>
    </v-row>
      <v-row class="pt-lg-14 ">
        <v-col cols="12">
          <v-row justify="center" class="px-10 px-lg-16">
            <h1 class="text-h5 text-lg-h4 copperplate text-center mb-8">{{$t('contact.ourTeam')}}</h1>
            <p class="p josefin-light mb-8 text-center text-lg-justify"
            v-html="$t('contact.ourTeamDescription')">
            </p>
          </v-row>
          <v-row justify="center">
            <a :href="`${publicPath}formulario.pdf`" download="download">
              <v-btn class="btn_second download_form mb-10" elevation="6" medium
                >{{$t('contact.btnDownloadForm')}}</v-btn
              >
            </a>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import banner from "@/components/home/banner.vue";
import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {
  components: {
    banner
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      loading: false,
      emailMessage: {
        name: "",
        email: "",
        message: ""
      },
    };
  },
  computed: {
    coordinateString() {
      return `${this.coordinates.lat},${this.coordinates.lng}`;
    },
    validationRules() {
      return {
        nameRules: [
          (v) => !!v || "Campo requerido",
          (v) => (v && v.length <= 60) || "60 carácteres máximo"
        ],
        emailRules: [
          (v) => !!v || "Campo requerido",
          (v) => /.+@.+\..+/.test(v) || "El correo no es válido"
        ],
        messageRules: [
          (v) => !!v || "Campo requerido",
          (v) => (v && v.length <= 450) || "450 carácteres máximo"
        ]
      };
    }
  },
  methods: {
    ...mapActions("contacts", ["sendEmailMessage"]),
    async onSubmit() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();
      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha();

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          container: "contact-message-modal",
          confirmButton:
            "btn-success v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default",
          title: "h6"
        },
        buttonsStyling: false
      });
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          await this.sendEmailMessage({
            ...this.emailMessage,
            "g-recaptcha-response": token
          });
          this.$refs.form.reset();
          swalWithBootstrapButtons.fire({
            title: "¡Tu mensaje se ha enviado exitosamente!",
            text: "Estaremos respondiendo su mensaje a la mayor brevedad posible.",
            confirmButtonText: "Aceptar",
            icon: "success"
          });
        } catch (error) {
          console.log(error);
          swalWithBootstrapButtons.fire({
            title: "¡Hemos tenido un problema!",
            text: "Inténtalo de nuevo más tarde",
            confirmButtonText: "Aceptar",
            icon: "error"
          });
        } finally {
          this.loading = false;
        }
      }
    },
  }
};
</script>
<style scoped>
 a {
   color: #464646;
 }
</style>
